import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
// import Header from '../components/header/Header';
import Master from '../container/Master';
// import { history } from '../router/AppRouter';

export const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    routeName=false,
    ...rest
}) => {
    // console.log('Route props', routeName)
    return(
        <Route {...rest} component={(props) => {
            
             
            return(
            // isAuthenticated ? (
            //     <React.Fragment>
            //         <Header />
            //         <Component {...props} />
            //     </React.Fragment>
            // ) : (
            //     <Redirect to="/" />
            // )
            isAuthenticated ? <Master component={Component} routeName={routeName} {...rest} {...props} /> : <Redirect to="/" />
        )}} />
    );
}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.isLoggedIn
});

export default connect(mapStateToProps)(PrivateRoute);