import { clearLoginError } from "../actions/auth";

const teamsReducerDefaultState = {
  pinboards: [],
  folders: [],
  members: [],
  team_name: "",
  team_description: null,
  activity: {},
  role: "",
  mainRefreshFlag: false,
  isTeamPinboardExpanded: false,
  isTeamFolderExpanded: false,
  selectedKey: "1",
  isRequestsPresent: false,
  fromNav: false,
  fromPinboard: false,
  fromFolder: false,
  selfPinned: [],
  expandedTeamsFolders: [],
  expandedTeamsFolderPersist: [],
  activityCount: 0,
  showMore: false,
  showMoreFolders: false,
};

//Rename your payload in this structure {action:'', payload:''}

export default (state = teamsReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_TEAMS_DATA":
      return {
        ...state,
        role: action.payload.role || state.role,
        team_name: action.payload.team_name || state.team_name,
        team_description:
          action.payload.team_description !== null
            ? action.payload.team_description
            : state.team_description,
      };

    case "SET_ACTIVITY":
      return {
        ...state,
        activity: action.activity,
      };

    //used onLoad, when a new pin is created to get all related data and when someone removes an article from pinboard
    case "SET_TEAMS_PINBOARDS":
      return {
        ...state,
        pinboards: [
          // First, update or keep existing pinboards, and add new pinboards
          ...action.payload.pinData?.map((newPinboard) => {
            // Find the existing pinboard from the state
            const existingPinboard = state.pinboards.find(
              (pinboard) => pinboard.pinboard_id === newPinboard.pinboard_id
            );

            // If an existing pinboard is found, merge it with the new pinboard data
            if (existingPinboard) {
              return { ...existingPinboard, ...newPinboard };
            } else {
              // If no existing pinboard is found, just return the new pinboard
              return newPinboard;
            }
          }),
        ],
      };

    case "ADD_TEAMS_PINBOARDS":
      return {
        ...state,
        pinboards: [...state.pinboards, action.payload.pinData],
      };

    case "RENAME_TEAMS_PINBOARD":
      return {
        ...state,
        pinboards: state.pinboards.map((pinboard) =>
          pinboard.pinboard_id === action.payload.pinboard_id
            ? { ...pinboard, pinboard_name: action.payload.pinboard_name }
            : pinboard
        ),
      };
    case "DELETE_TEAMS_PINBOARDS":
      return {
        ...state,
        pinboards: state.pinboards.filter(
          (pinboard) => !action.payload.includes(pinboard.pinboard_id)
        ),
      };

    case "UPDATE_UNREAD":
      return {
        ...state,
        pinboards: state.pinboards.map((pinboard) =>
          pinboard.pinboard_id === action.payload.pinboard_id
            ? {
                ...pinboard,
                unread_posts:
                  action.payload.is_read == 1
                    ? pinboard.unread_posts + 1
                    : Math.max(0, pinboard.unread_posts - 1),
              }
            : pinboard
        ),
      };
    case "ADD_ARTICLE_UPDATE_UNREAD":
      return {
        ...state,
        pinboards: state.pinboards.map((pinboard) =>
          pinboard.pinboard_id === action.payload.pinboard_id
            ? {
                ...pinboard,
                unread_posts: parseInt(pinboard.unread_posts, 10) + 1,
              }
            : pinboard
        ),
      };

    case "PINBOARD_STATUS_UPDATE":
      return {
        ...state,
        pinboards: state.pinboards.map((pinboard) =>
          pinboard.pinboard_id === action.payload.pinboard_id
            ? {
                ...pinboard,
                status: action.payload.status == 0 ? "empty" : "Followed",
              }
            : pinboard
        ),
      };

    case "TOGGLE_REFRESH_FLAG":
      return {
        ...state,
        mainRefreshFlag: !state.mainRefreshFlag,
      };

    case "TOGGLE_TEAMS_PINBOARD":
      return {
        ...state,
        isTeamPinboardExpanded: !state.isTeamPinboardExpanded,
      };

    case "TOGGLE_TEAMS_FOLDER":
      return {
        ...state,
        isTeamFolderExpanded: !state.isTeamFolderExpanded,
      };

    case "EXPAND_FOLDER_FEED":
      // console.log(state.expandedTeamsFolderPersist);
      // console.log(action.payload.folder_id);
      return {
        ...state,
        expandedTeamsFolders:
          action.payload.status == 1
            ? [...state.expandedTeamsFolders, action.payload.folder_id]
            : state.expandedTeamsFolders.filter(
                (folder_id) => folder_id != action.payload.folder_id
              ),
        expandedTeamsFolderPersist:
          action.payload.status == 1
            ? state.expandedTeamsFolderPersist.includes(
                action.payload.folder_id
              )
              ? state.expandedTeamsFolderPersist // Avoid duplicates
              : [...state.expandedTeamsFolderPersist, action.payload.folder_id]
            : state.expandedTeamsFolderPersist,
      };

    case "TOGGLE_FROM_NAV":
      return {
        ...state,
        fromNav: action.payload,
      };
    case "FROM_PINBOARD":
      return {
        ...state,
        fromPinboard: action.payload,
      };

    case "FROM_FOLDER":
      return {
        ...state,
        fromFolder: action.payload,
      };

    // case "SET_SELF_PINNED":
    //   return {
    //     ...state,
    //     selfPinned:
    //       mode == "remove"
    //         ? state.selfPinned.filter(
    //             (pin) => pin.pinboard_id != action.payload.pinboard_id
    //           )
    //         : [...prevState.selfPinned, pinboard_id],
    //   };

    case "SET_TEAMS_FOLDERS":
      return {
        ...state,
        folders: [
          // First, update existing folders and add new ones from the payload
          ...action.payload.fldData?.map((newFolder) => {
            // Find the existing folder from the state
            const existingFolder = state.folders.find(
              (folder) => folder.folder_id === newFolder.folder_id
            );

            // If an existing folder is found, merge it with the new folder data
            if (existingFolder) {
              return { ...existingFolder, ...newFolder };
            } else {
              // If no existing folder is found, just return the new folder
              return newFolder;
            }
          }),
        ],
      };

      case "UPDATE_UNREAD_FOLDER":
        return {
          ...state,
          folders: state.folders.map((folder) => {
            if (folder.children) {
              // Check if the folder's children include the feed_id
              const hasMatchingFeed = folder.children.some(
                (feed) => feed.feed_id == action.payload.feed_id
              );
      
              if (hasMatchingFeed) {
                // Update the children feeds
                const updatedChildren = folder.children.map((feed) => {
                  if (feed.feed_id == action.payload.feed_id) {
                    const unreadCount = parseInt(feed.unread_count || 0, 10);
                    const updatedUnreadCount =
                      action.payload.is_read === 1
                        ? unreadCount + 1
                        : unreadCount - 1;
                    return {
                      ...feed,
                      unread_count: updatedUnreadCount,
                    };
                  }
                  return feed;
                });
      
                // Calculate the total unread_posts for the folder based on updated children
                const totalUnreadPosts = updatedChildren.reduce(
                  (sum, child) => sum + parseInt(child.unread_count || 0, 10),
                  0
                );
      
                return {
                  ...folder,
                  unread_posts: Math.max(totalUnreadPosts,0),
                  children: updatedChildren,
                };
              }
            }
      
            // If the folder has no children and matches the folder_id in the payload, update unread_posts
            if (folder.folder_id == action.payload.folder_id) {
              const updatedUnreadPosts =
                action.payload.is_read === 1
                  ? (folder.unread_posts || 0) + 1
                  : Math.max((folder.unread_posts || 0) - 1, 0);
      
              return {
                ...folder,
                unread_posts: updatedUnreadPosts,
              };
            }
      
            // If no matching feed or folder_id, return the folder as is
            return folder;
          }),
        };
      

    // case "SET_TEAMS_FOLDERS":
    //   return {
    //     ...state,
    //     folders: [
    //       ...state.folders.map((existingFolder) => {
    //         // Check if there's an updated version of the existing folder in the new data
    //         const updatedFolder = action.payload.pinData?.find(
    //           (newFolder) => newFolder?.folder_id === existingFolder.folder_id
    //         );

    //         // If an updated version exists, replace the existing folder with the updated data
    //         return updatedFolder
    //           ? { ...existingFolder, ...updatedFolder }
    //           : existingFolder;
    //       }),
    //       // Add any new folders that don’t already exist in state.folders
    //       ...action.payload.pinData?.filter(
    //         (newFolder) =>
    //           !state.folders.some(
    //             (existingFolder) =>
    //               existingFolder.folder_id === newFolder?.folder_id
    //           )
    //       ),
    //     ],
    //   };

    case "DELETE_TEAMS_FOLDERS":
      return {
        ...state,
        folders: state.folders.filter(
          (folder) => !action.payload.includes(folder.folder_id)
        ),
      };

    case "FOLDER_STATUS_UPDATE":
      return {
        ...state,
        folders: state.folders.map((folder) =>
          folder.folder_id === action.payload.folder_id
            ? {
                ...folder,
                followed: action.payload.status == 0 ? "0" : "1",
              }
            : folder
        ),
      };

    case "CHANGE_PIN_ROLE":
      return {
        ...state,
        pinboards: state.pinboards.map((pin) =>
          pin.pinboard_id == action.payload.pinboard_id
            ? {
                ...pin,
                pinboard_role_id: action.payload.pinboard_role_id,
              }
            : pin
        ),
      };

    case "SET_KEY":
      return {
        ...state,
        selectedKey: action.payload,
      };

    case "REQUESTS_PRESENT":
      return {
        ...state,
        isRequestsPresent: action.payload,
      };

    case "SET_TEAM_FEED":
      // console.log("HELLO 5", action.payload);
      return {
        ...state,
        folders: state.folders.map((fld) => {
          // Check if the folder_id matches the payload folder_id
          if (fld.folder_id == action.payload.folder_id) {
            // Replace children entirely with the payload's children
            return {
              ...fld,
              children: action.payload.children || [], // Fallback to an empty array
            };
          }
          // If the folder doesn't match the payload folder_id, return the folder unchanged
          return fld;
        }),
      };

    case "CHANGE_FLD_ROLE":
      return {
        ...state,
        folders: state.folders.map((fld) =>
          fld.folder_id == action.payload.folder_id
            ? {
                ...fld,
                folder_role_id: action.payload.folder_role_id,
              }
            : fld
        ),
      };

    case "SET_ACTIVITY_COUNT":
      return {
        ...state,
        activityCount: parseInt(action.payload),
      };

    case "TOGGLE_SHOW_MORE":
      return {
        ...state,
        showMore: !state.showMore,
      };

    case "TOGGLE_SHOW_MORE_FOLDERS":
      return {
        ...state,
        showMoreFolders: !state.showMoreFolders,
      };

    // case "SELF_ROLE_CHANGE":

    // case 'ADD_PINBOARD':

    //     return {
    //         ...state,
    //         pinboards:[...state.pinboards,action.pinboard]
    //     }

    // case 'DELETE_PINBOARD':
    //     return {
    //         ...state,
    //         pinboards: state.pinboards.filter(pinboard => !action.ids.includes(pinboard.id))
    //     }

    // case 'ADD_PINBOARD_MEMBER':{
    //     const {pinboard_id, newMember} = action.member;
    //     return {
    //         ...state,
    //         pinboards:state.pinboards.map(pinboard =>  {
    //             if(pinboard.id === pinboard_id){
    //                 return {
    //                     ...pinboard,
    //                     pinboard_members:[...pinboard.pinboard_members,newMember]
    //                 };
    //             }
    //             return pinboard;
    //         })
    //     }
    // }

    // case 'DELETE_PINBOARD_MEMBER':{
    //     const{pinboard_id,member_id} = action.pinboard;
    //     return {
    //         ...state,
    //         pinboards:state.pinboards.map(pinboard => {
    //             if(pinboard.id === pinboard_id){
    //                 return {
    //                     ...pinboard,
    //                     pinboard_members:pinboard.pinboard_members.filter(member => member.id !==member_id)
    //                 };
    //             }
    //             return pinboard;
    //         })
    //     }
    // }

    // case 'ADD_MEMBER':
    //     return {
    //         ...state,
    //         members:[...state.members,action.member]
    //     }

    // case 'DELETE_MEMBER':
    //     return {
    //         ...state,
    //         members: state.members.filter(member => member.id !== action.id)
    //     }

    default:
      return state;
  }
};
