import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { ToastContainer } from 'react-toastify';
// import Shared from '../components/shared/Shared';
const Shared = lazy(() => import('../components/shared/Shared'));
import { Detector, Offline, Online } from "react-detect-offline"
import { Modal,Spinner } from 'react-bootstrap';
// import Header from '../components/header/Header'
// import { makeStyles } from '@material-ui/core/styles';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import FaviconUnread from '../../images/favicon/favicon-unread.png'
import Favicon from '../../images/favicon/favicon.png'
import { startUpdateSelected } from '../actions/subscription';
import Hotjar from '@hotjar/browser';

// const useStyles = makeStyles((theme) => ({
//     root: {
//       flexGrow: 1,
//     },
//     menuButton: {
//       marginRight: theme.spacing(2),
//     },
//     title: {
//       flexGrow: 1,
//     },
//   }));

// export default function Master() {
//     const classes = useStyles();
  
//     return (
//       <Box color="text.primary" clone>
//         <Component {...newProps} />
//       </Box>
//     );
// }

// export default class Master extends React.Component {

//     state = {
//         isActiveSidebarLarge: true
//     }

//     updateSidebarLarge = () => {
//         this.setState(prevState=>({
//             isActiveSidebarLarge: !prevState.isActiveSidebarLarge
//         }));
//     }

//     page = () => {
//         const { location } = this.props;
//         const { pathname } = location;

//         const accountsPattern = /^\/account\//i;

//         let page = false;
//         if(accountsPattern.test(pathname)) {
//             page = "accounts";
//         }
//         else if (pathname == "/home") {
//             page = "home";
//         }
//         return page;        
//     }

//     render() {
//         const { isActiveSidebarLarge } = this.state;
//         const { component:Component, ...rest } = this.props;

//         const newProps = {
//             ...rest,
//             isActiveSidebarLarge
//         }

//         const page = this.page();

//         return (
//             <>
//                 {/* <Header page={page} updateSidebarLarge={this.updateSidebarLarge} /> */}
//                 <Component {...newProps} />
//             </>
//         );
//     }

// }



const Master = (props) => {
  const [isActiveSidebarLarge, setIsActiveSidebarLarge] = useState(true);
  // console.log('master props', props)

  const dispatch = useDispatch();
  
  const { component:Component, ...rest } = props;
  // console.log(props)
  const newProps = {
                ...rest,
                isActiveSidebarLarge
            }

            // console.log('newProps',newProps)

  const onSelectMenu = (info) => {
              const { key, id, title, type, page="all", force=false, route=false } = info;
              dispatch(startUpdateSelected({
                  type,
                  id,
                  key,
                  name: title,
                  page,
                  force,
                  route: !!route? route : page,
              }));
            }

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", Favicon);
    !!props.routeName && manageRouteSegment(props);
    startHotjar();
  });

  const startHotjar = () => {
    const siteId = process.env.HOTJAR_SITE_ID;
    const hotjarVersion = 6;
    process.env.HOTJAR_ENABLE==1 && Hotjar.init(siteId, hotjarVersion);
  }

  const manageRouteSegment = (props) => {
    const { routeName } = props;
    switch (routeName) {
      case "favorite":
        onSelectMenu({key:"favorite", id:"favorite", title:"Favorited Posts", type:"favorite", page:"all", route:"favorite"});
        break;
      // case "pod_2":
      // case "pod_3":
      //   onSelectMenu({...props.selected,key:"podcast", type:"podcast", page:"podcast", category:"podcast", navigate:"ignore"});
        // break;
      default:
        break;
    }
  }

  // error reload chunk start val  
  // const origin = console.error;
  // console.error = (error) => {
  //     if (/Loading chunk [\d]+ failed/.test(error.message)) {
  //       alert('A new version released. Need to relaod the page to apply changes.')
  //       window.location.reload();
  //     } else {
  //       origin(error);
  //     }
  // }
  window.addEventListener('error', e => {
    // prompt user to confirm refresh
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
      window.location.reload();
    }
  });
  // error reload chunk start val 

  // const page = () => {
  //   const { location } = props;
  //   const { pathname } = location;
  
  //   const accountsPattern = /^\/account\//i;
  
  //   let page = false;
  //   if(accountsPattern.test(pathname)) {
  //       page = "accounts";
  //   }
  //   else if (pathname == "/home") {
  //       page = "home";
  //   }
  //   return page;        
  // }


  return (
    <>
    <ToastContainer icon={false} />
    <Suspense fallback={<div></div>}>
      <Component {...newProps} />
    </Suspense>
    <Suspense fallback={<div></div>}>
      <Shared />
    </Suspense>
    
{/* <script
dangerouslySetInnerHTML={{
__html: `
  (function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2727980,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
}}
 /> */}


    <Detector
      polling={{
        // url:"https://ipv4.icanhazip.com",
        url:process.env.API_AUTH_ENDPOINT_2+'public/status',
        interval:15000,
      }}
      render={({ online }) => (
    <Modal
      show={!online}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>&#9888; Connection Error</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='text-center'>
          <p>The Internet connection appears to be offline.</p>
          <Spinner className="m-4" animation="border" variant="primary" />
          <div className='text-muted'>Trying to reconnect...</div>
        </div>
      </Modal.Body>
    </Modal>
  )}
/>
    </>
  )
}

export default Master;