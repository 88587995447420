import { callToast } from "./Utilities";
import { isApiSuccess } from "./Utilities";
import { ApiConstants } from "../constants/api";

export const makeApiRequest = async (url, method, token, body) => {
  // console.log("make api request reached:", url);

  const headers = {
    Authorization: token,
    "Content-Type": "application/x-www-form-urlencoded",
  };

  let response;

  // Check if body is empty (either an empty string or empty URLSearchParams)
  const hasBody = body && body.toString().trim() !== "";

  // console.log(hasBody);
  if (method === "GET") {
    if (hasBody) {
      const queryParams = buildUrlEncodedBody(body); // Convert URLSearchParams to string
      // console.log(queryParams)
      url = `${url}?${queryParams}`; // Append query params to URL
      // console.log(url)
    }
    response = await fetch(url, {
      method: method,
      headers: headers,
    });
  } else {
    response = await fetch(url, {
      method: method,
      headers: headers,
      body: hasBody ? body.toString() : undefined, // Only include body if it's non-empty
    });
  }

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const buildUrlEncodedBody = (params) => {
  const body = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      // Convert the array to a JSON string
      body.append(key, JSON.stringify(value));
    } else {
      body.append(key, value);
    }
  });
  return body;
};
// Fetch team members API
export const fetchTeamMembersApi = async (token, limit, offset, status) => {
  const url = ApiConstants?.TEAM_LIST;

  const params = {
    status: status,
    limit: limit,
    offset: offset,
  };

  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body);
};

export const handleInvite = async (token, email_id, status) => {
  const url = ApiConstants?.TEAM_INVITE;

  const params = new URLSearchParams();

  // For 'invite' and 're_invite' status
  if (status === "re_invite" || status === "invite") {
    params.append("email_id", email_id);
    params.append("status", status);
  }
  // For deleting members, map through email_id array
  else {
    email_id.forEach((email, index) => {
      params.append(`delete_members[${index}][email_id]`, email);
    });
    params.append("status", status);
  }

  // console.log("Encoded params to be sent:", params.toString());

  // Call the API with form-encoded params
  return await makeApiRequest(url, "POST", token, params);
};

export const deleteMembers = async (token, user_id) => {
  const url = ApiConstants?.TEAM_MEMBER_DELETE;

  const params = new URLSearchParams();
  user_id.forEach((userid, index) => {
    params.append(`delete_members[${index}][userid]`, userid);
  });
  // console.log("Encoded params to be sent:", params.toString());

  return await makeApiRequest(url, "POST", token, params);
};

export const changeUserRole = async (token, userid, role_id) => {
  const url = ApiConstants?.TEAM_MANAGE_ROLE;

  const params = {
    role_id,
    userid,
  };

  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body);
};

export const findRows = async (token, limit, offset, mode, key) => {
  const url = ApiConstants?.TEAM_SEARCH;

  const params = {
    limit,
    offset,
    mode,
    key,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body);
};

export const createTeam = async (token, team_name, team_description) => {
  const url = ApiConstants?.TEAM_CREATE;
  const params = {
    team_name,
    team_description,
  };
  const body = buildUrlEncodedBody(params);
  const response = await makeApiRequest(url, "POST", token, body);
  return isApiSuccess(response.status);
  // if(response.status){
  //   console.log("Team created",response.msg)
  // }
};

export const getPinboard = async (token, limit, offset, mode, key = "") => {
  const url = ApiConstants?.PINBOARD_GET_PINBOARD;
  const params = {
    offset,
    limit,
    mode,
    key,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body);
};

export const PinboardMembers = async (
  token,
  status,
  pinboard_id,
  limit = "",
  offset = ""
) => {
  const url = ApiConstants?.PINBOARD_MEMBERS;
  const params = {
    status,
    pinboard_id,
    limit,
    offset,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body).then((res) => {
    if (isApiSuccess(res.status)) {
      // console.log("pin data",res.data)
      return res;
    } else {
      console.error(res.msg);
      return res.status;
    }
  });
};

export const TeamsPlan = async (token) => {
  const url = ApiConstants?.TEAMS_MODAL_INFO;

  const body = "";
  const response = await makeApiRequest(url, "GET", token, body);
  // console.log(response)
  return response;
};

export const addMemberToTeam = async (token, encrypted_id, status) => {
  const url = ApiConstants?.TEAM_ADD_MEMBER;
  const params = {
    encrypted_id,
    status,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body).then((res) => {
    if (isApiSuccess(res.status)) {
      callToast({ type: "success", msg: "Done!" });
      return res;
    } else {
      callToast({ type: "error", msg: res.msg });
      setTimeout(() => {
        window.location.href = "/reader";
      }, 1000);
    }
  });
};

export const addMemberToPinBoard = async (token, pinboard_id, userid) => {
  const url = ApiConstants?.PINBOARD_ADD_MEMBER;
  const params = {
    pinboard_id,
    userid,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        // console.log("add mem")
        callToast({ type: "success", msg: "Member added successfully" });
        return res;
      } else {
        callToast({ type: "error", msg: "Something went wrong" });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deletePinboards = async (token, pinboard_id) => {
  const url = ApiConstants?.PINBOARD_DELETE_PINBOARD;

  const params = new URLSearchParams();
  pinboard_id.forEach((pinboard_id, index) => {
    params.append(`deleted_pinboards[${index}][pinboard_id]`, pinboard_id);
  });
  // console.log("Encoded params to be sent:", params.toString());

  return await makeApiRequest(url, "POST", token, params);
};

export const RenamePinboard = async (token, pinboard_id, pinboard_name) => {
  const url = ApiConstants?.PINBOARD_RENAME_PINBOARD;
  const params = {
    pinboard_id,
    pinboard_name,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        callToast({ type: "success", msg: "Pinboard Renamed Successfully!" });
        return true;
      } else {
        callToast({ type: "error", msg: res.msg });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const addArticlePinboard = async (token, pinboard_id, entry_id) => {
  const url = ApiConstants?.PINBOARD_PIN_ARTICLE;
  const params = {
    pinboard_id,
    entry_id,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body)
    .then((res) => {
      // console.log(res);
      if (res.status) {
        callToast({ type: "success", msg: "Article added successfully" });
        return true;
      } else {
        callToast({ type: "error", msg: "something went wrong" });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deletePinboardMembers = async (token, userid, pinboard_id) => {
  const url = ApiConstants?.PINBOARD_DELETE_MEMBER;
  const params = {
    token,
    userid,
    pinboard_id,
  };
  const body = buildUrlEncodedBody(params);
  const response = await makeApiRequest(url, "POST", token, body);
  // console.log(response)
  return response;
};

export const changePinboardUserRole = async (
  token,
  pinboard_role_id,
  userid,
  pinboard_id
) => {
  const url = ApiConstants?.PINBOARD_MANAGE_ROLE;
  const params = {
    pinboard_id,
    pinboard_role_id,
    userid,
  };
  const body = buildUrlEncodedBody(params);
  // console.log(body.toString())
  return makeApiRequest(url, "POST", token, body);
};

export const getAllRequestsData = async (token) => {
  const url = ApiConstants?.PINBOARD_REQUEST_DATA;
  return makeApiRequest(url, "GET", token, []).then((res) => {
    if (isApiSuccess(res.status)) {
      // callToast({type:"success",msg:"You got new requests!"});
      return res.data;
    } else {
      // callToast({type:"error",msg:"Something went wrong"});
      return [];
    }
  });
};

export const manageRequests = async (token, userData) => {
  const url = ApiConstants?.PINBOARD_MANAGE_REQUEST;

  const params = new URLSearchParams();
  userData.forEach((userdata, index) => {
    params.append(`manage_members[${index}][userid]`, userdata.userid);
    params.append(
      `manage_members[${index}][pinboard_id]`,
      userdata.pinboard_id
    );
    params.append(`manage_members[${index}][status]`, userdata.status);
  });
  // console.log("Encoded params to be sent:", params.toString());

  return await makeApiRequest(url, "POST", token, params).then((res) => {
    if (isApiSuccess(res.status)) {
      callToast({ type: "success", msg: "Action performed successfully!" });
      return res;
    } else {
      callToast({ type: "error", msg: "Something went wrong" });
    }
  });
};

export const unfollowFollowPinboard = async (token, pinboardId, status) => {
  const url = ApiConstants?.PINBOARD_FOLLOW;

  const params = new URLSearchParams();
  params.append("pinboard_id", pinboardId);
  params.append("status", status);

  // console.log("Encoded params to be sent:", params.toString());

  return await makeApiRequest(url, "POST", token, params).then((res) => {
    // console.log(res)
    if (isApiSuccess(res.status)) {
      callToast({
        type: "success",
        msg: `${
          status === "follow" ? "Requested Access " : "Unfollowed "
        }successfully!`,
      });
      return res;
    } else {
      callToast({ type: "error", msg: res.msg });
    }
  });
};

export const teamMemberCountWithLimit = async (token) => {
  const url = ApiConstants?.TEAM_COUNT_LIMIT;
  return makeApiRequest(url, "GET", token, []).then((res) => {
    if (isApiSuccess(res.status)) {
      return res.data;
    } else {
      // callToast({type:"error",msg:"Something went wrong"});
      return null;
    }
  });
};

export const teamsActivity = async (token, limit, lastActivityId) => {
  const url = ApiConstants?.TEAM_ACTIVITY;

  const params = new URLSearchParams();
  params.append("limit", limit);
  params.append("last_record", lastActivityId);

  // console.log("Encoded params to be sent:", params.toString());

  return await makeApiRequest(url, "POST", token, params).then((res) => {
    // console.log("teamsActivity",res)
    if (isApiSuccess(res.status)) {
      // callToast({type:"success",msg:`successfully!`});
      return res;
    } else {
      callToast({ type: "error", msg: res.msg });
    }
  });
};

// =====================FOLDER

export const getSearchSiteApi = async (key, token) => {
  const url = `${ApiConstants?.FOLDER_SEARCH_SITE}`;
  const params = {
    q: key,
  };
  const body = buildUrlEncodedBody(params);
  try {
    const response = await makeApiRequest(url, "POST", token, body);
    if (isApiSuccess(response.status)) {
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const getFolder = async (token, limit, offset, mode, key = "") => {
  const url = ApiConstants?.FOLDER_GET_DATA;
  const params = { offset, limit, mode, key };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body);
};

export const FolderMembers = async (
  token,
  action,
  folder_id,
  limit = "",
  offset = ""
) => {
  const url = ApiConstants?.FOLDER_MEMBERS;
  const params = {
    action,
    folder_id,
    limit,
    offset,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body).then((res) => {
    // console.log('aaa res', res)
    if (isApiSuccess(res.status)) {
      return res;
    } else {
      console.error(res.msg);
      return res.status;
    }
  });
};

export const deleteFolderMembers = async (token, userid, folder_id) => {
  const url = ApiConstants?.FOLDER_DELETE_MEMBER;
  const params = {
    token,
    userid,
    folder_id,
  };
  const body = buildUrlEncodedBody(params);
  const response = await makeApiRequest(url, "POST", token, body);
  return response;
};

export const addMemberToFolder = async (token, folder_id, userid) => {
  const url = ApiConstants?.FOLDER_ADD_MEMBER;
  const params = {
    folder_id,
    userid,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        // console.log("add mem")
        callToast({ type: "success", msg: "Member added successfully" });
        return res;
      } else {
        callToast({ type: "error", msg: res.msg });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

// export const getFeeds = async (token, folder_id) => {
//   const url = "https://api2.devteams.feedspot.com/v2/teams/folder/get_feeds";
//   const params = {
//     folder_id,
//   };
//   const body = buildUrlEncodedBody(params);
//   return makeApiRequest(url, "POST", token, body)
//     .then((res) => {
//       if (isApiSuccess(res.status)) {
//         return res.data.result;
//       } else {
//         console.error(res.msg);
//       }
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// };

export const digestSchedule = async (
  token,
  digest_id,
  start_date,
  schedule_time,
  schedule_type,
  schedule_custom = []
) => {
  const url = ApiConstants.SET_DIGEST_SCHEDULE;
  const params = {
    digest_id,
    start_date,
    schedule_time,
    schedule_type,
    schedule_custom,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        callToast({ type: "success", msg: "Schedule set" });
        return true;
      } else {
        callToast({ type: "error", msg: res.msg });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const digestContent = async (token, digest_id, section_data) => {
  const url = ApiConstants.SET_DIGEST_CONTENT;
  const params = {
    digest_id,
    section_data,
  };
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        callToast({ type: "success", msg: "Content set" });
        return true;
      } else {
        callToast({ type: "error", msg: res.msg });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getDigest = async (token, limit, offset, mode, key = "") => {
  const url = ApiConstants.GET_DIGEST;
  const params = {
    offset,
    limit,
    mode,
    key,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body);
};

export const getRecipient = async (
  token,
  limit,
  offset,
  mode,
  key = "",
  digest_id = ""
) => {
  // console.log(digest_id);
  const url = ApiConstants.GET_DIGEST_RECIPIENT;
  const params = {
    offset,
    limit,
    mode,
    key,
    digest_id,
  };

  return await makeApiRequest(url, "GET", token, params);
};

export const sendDigestInvite = async (
  token,
  digest_id,
  email,
  mode = "Send"
) => {
  const url = ApiConstants.SEND_DIGEST_INVITE;
  const params = {
    digest_id,
    email,
    mode,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        callToast({
          type: "success",
          msg: `${mode == "Send" ? "Invited" : "Re-invited"} Successfully`,
        });
        return true;
      } else {
        callToast({ type: "error", msg: res.msg });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

// export const deleteRecipient = async (token, digest_id, emails) => {
//   const url =
//     "https://api.devteams.feedspot.com/v2/teams/digest/recipients/delete";
//   const params = {
//     digest_id,
//     emails,
//   };
//   const body = buildUrlEncodedBody(params);
//   return await makeApiRequest(url, "POST", token, body);
// };

export const deleteDigest = async (token, digest_id) => {
  // console.log(digest_id)
  const url = ApiConstants.DELETE_DIGEST;
  const params = new URLSearchParams();

  digest_id.forEach((id, index) => {
    params.append(`delete_digests[${index}][digest_id]`, id);
  });
  return await makeApiRequest(url, "POST", token, params);
};

export const toggleDigest = async (token, digest_id, action) => {
  const url = ApiConstants.TOGGLE_DIGEST;
  const params = {
    digest_id,
    action,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        callToast({
          type: "success",
          msg: `Digest ${action == "activate" ? "activated" : "de-activated"}`,
        });
        return true;
      } else {
        callToast({ type: "error", msg: res.msg });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const digestFollowUnfollow = async (token, digest_id, status) => {
  let url = ApiConstants.DIGEST_FOL_UNFOL;
  url += status == 1 ? "follow" : "unfollow";
  const params = {
    digest_id,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", token, body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        callToast({
          type: "success",
          msg: `${
            status == 1
              ? res.optin == true
                ? "Followed"
                : "Requested"
              : "Unfollowed"
          } successfully`,
        });
        return true;
      } else {
        callToast({ type: "error", msg: res.msg });
        return false;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getDigestContent = async (token, digest_id) => {
  const url = ApiConstants.GET_DIGEST_CONTENT;
  const params = {
    digest_id,
  };
  return await makeApiRequest(url, "GET", token, params);
};

export const getDigestSchedule = async (token, digest_id) => {
  const url = ApiConstants.GET_DIGEST_SCHEDULE;
  const params = {
    digest_id,
  };
  return await makeApiRequest(url, "GET", token, params);
};

export const getDigestPreview = async (token, digest_id) => {
  const url = ApiConstants.DIGEST_PREVIEW;
  const params = {
    digest_id,
  };
  return await makeApiRequest(url, "GET", token, params).then((res) => {
    if (isApiSuccess(res.status)) {
      return res.data;
    } else {
      // callToast({type:"error",msg:"Something went wrong"});
      return null;
    }
  });
};

export const getDigestLog = async (token, limit = 5, offset = 1, digest_id) => {
  const url = ApiConstants.GET_DIGEST_LOG;
  const params = {
    limit,
    offset,
    digest_id,
  };
  return await makeApiRequest(url, "GET", token, params);
};

export const getDigestLogPreview = async (token, log_id) => {
  const url = ApiConstants.DIGEST_LOG_PREVIEW;
  const params = {
    log_id,
  };
  return await makeApiRequest(url, "GET", token, params);
};

export const getDigestView = async (token, logId,userId) => {
  const url = ApiConstants.DIGEST_VIEW;
  const params = {
    logId,
    userId
  };
  return await makeApiRequest(url, "GET", token, params);
};

export const getSubscribeUserData = async (userId, timestamp="",mode) => {
  const url = ApiConstants.SUBSCRIBE_USER_DATA;
  const params = {
    userId,
    timestamp,
    mode
  };
  return await makeApiRequest(url, "GET", " ", params)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        return res.data;
      } else {
        console.error(res);
        return null;
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const subscribeToDigest = async (userId, mode) => {
  const url = ApiConstants.SUBSCRIBE_TO_DIGEST;
  const params = {
    userId,
    mode,
  };
  const body = buildUrlEncodedBody(params);
  return await makeApiRequest(url, "POST", "", body)
    .then((res) => {
      if (isApiSuccess(res.status)) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
