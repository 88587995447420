import { ApiConstants } from '../constants/api';
import { callToast, isApiSuccess, createFormData} from '../service/Utilities'
import { buildUrlEncodedBody, makeApiRequest } from './TeamsApi';
// import axios from 'axios';

export async function callLoginApi(login, password) {
  let authString = `${login}:${password}`;
  let headers = new Headers();
  headers.set('Authorization', 'Basic ' + btoa(authString))
  const response = await fetch(ApiConstants.AUTH_ACCESS_TOKEN, {
    method: 'POST',
    cache: 'no-cache',
    headers
  });
  // const response = await axios({
  //   method: 'post',
  //   url: ApiConstants.AUTH_ACCESS_TOKEN,
  //   headers
  // });
  return response.json();
}

export async function callApi({method = 'GET', url = '', data = "", token = "", isFormdata = false, files=[], type="json"} = {}) {
  let headers = new Headers();
  if(!isFormdata){
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
  }
  if(!!token) {
    headers.set('Authorization', token);
  }
  let settings = {
    method,
    cache: 'no-cache',
    headers,
  }
  if(!!data) {
    let params;
    if(isFormdata) {
      params = new FormData();
      // console.log('data', data)
      Object.entries(data).map(([key, value]) => {
        createFormData(params, key, value, files)
      });
    }
    else {
      params = Object.keys(data).map(function(k) {
        return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
      }).join('&');
    }
    if(method.toUpperCase() == 'POST' || method.toUpperCase() == 'DELETE') {
      // settings.body = JSON.stringify(data);
      settings.body = params;
    }
    else {
      url += '?' + params;
    }
  }
  const response = await fetch(url, settings);
  if (!response.ok && !response.status) {
    throw new Error(response);
  }
  if(type == "json")
  {
    return response.json();
  }
  if(type == "blob")
  {
    return response.blob()
  }
  else {
    return response;
  }
}

export const getFeedInfo = ({feed_id, token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.FEED_INFO,
      token,
      data: {
        site_id:feed_id
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getSuggestion = ({query, filter="", token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.SUGGESTION,
      token,
      data: {
        q:query,
        type:filter
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getSpotlight = ({q, mode, feed_id="", token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.SPOTLIGHT_LIST,
      token,
      data: {
        q,
        type: mode,
        site_id: feed_id
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getSpotlightDefault = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.SPOTLIGHT_DEFAULT,
      token,
      // data: {
      //   q,
      //   type: mode,
      //   site_id: feed_id
      // }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getFolderDetail = ({folder_id, followers=0, token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.FOLDER_DETAIL,
    token,
    data: {
        folder_id,
        followers
    }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getTeamFolderDetail = async ({folder_id,token}) => {
  const url =ApiConstants.FOLDER_GET_FEEDS;
  const params = {
    folder_id
  }
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url,"POST",token,body)
  .then((res) => {
    if(isApiSuccess(res.status)){
      return res.data;
    }else{
      callToast({type:"error",msg:res.msg})
      return [];
    }
  })
  .catch((error) => {
    callToast({type:"error",msg:error})
  })
}

export const getFolderEntries = async ({limit=25,sort="asc",folder_id="",feed_entry_created="",feed_entry_id="",token,feed_id="",unread}) => {
  const url = unread ==1 ? ApiConstants.FOLDER_GET_UNREAD: ApiConstants.FOLDER_GET_ARTICLES;
  const params = {
    limit,
    feed_entry_created,
    feed_entry_id,
    sort,
    mode:"moreajax",
    feedId:feed_id,
    folderId:folder_id
  }
  const body = buildUrlEncodedBody(params);
  return makeApiRequest(url,"POST",token,body)
  .then((res)=>{
    if(isApiSuccess(res.status)){
      return res.data
    }else{
      callToast({type:"error",msg:res.msg})
    }
  })
  .catch((error) => {
    callToast({type:"error",msg:error})
  })
} 

// export const getTeamFolderDetail =() => {
//   return 
// }

export const getEntries = (props) => new Promise((resolve, reject) => {
  // console.log('getEntries aaaaaaaaaaa', props)
  const { limit=false, offset=false, feed_entry_id:entry_id=false, feed_entry_created:entry_created=false, feed_id:site_id=false, folder_id=false, tag_id=false, pinboard_id=false, sort=false, unread=0, starred=0, shared=0, pinboard=0,teamsPinboard=0, podcast=0, search=false, entry_ids=false, token, method="GET" } = props;

  let url = ApiConstants.ENTRIES;
  let data = {};
  data = limit ? { ...data, limit} : data;
  data = offset ? { ...data, offset} : data;
  data = entry_id ? { ...data, entry_id} : data;
  data = entry_created ? { ...data, entry_created} : data;
  data = site_id ? { ...data, site_id} : data;
  data = folder_id ? { ...data, folder_id} : data;
  data = pinboard_id ? { ...data, pinboard_id} : data;
  data = tag_id ? { ...data, tag_id} : data;
  data = sort ? { ...data, sort} : data;
  // data = unread ? { ...data, unread} : data;
  // data = starred ? { ...data, starred} : data;
  if(starred) {
    data = { ...data, mode:'starred'}
  }
  else if(pinboard) {
    data = { ...data, mode:'pinboard'}
  }
  else if(teamsPinboard){
    data = {...data, mode:'teams_pinboard'}
  }
  else if(unread) {
    data = { ...data, mode:'unread'}
  }
  else if(podcast) {
    data = { ...data, mode:'podcast'}
  }
  else if(!!search) {
    url = ApiConstants.SEARCH_SITES;
    data = { q:search, limit: data.limit,  offset: data.offset}
    // console.log('search data', data)
  }
  else {
    data = { ...data, mode:'all'}
  }
  data = shared ? { ...data, shared} : data;
  data = entry_ids ? { ...data, entry_ids} : data;

  // data = {...data, profession:1};

  // console.log('getEntries data aa', data)

  const apiData = {
      url,
      method,
      token,
      data
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const shareApi = ({entry_id, network_id, notbook_guid=0, token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.SHARE,
    token,
    method: "POST",
    data: {
      entry_id,
      network_id,
      notbook_guid: notbook_guid==0 ? undefined : notbook_guid
    }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        callToast({type:"success",msg:"Shared Successfully"})
        resolve(res);
      }
      else if([421,423,425].includes(res.status)) {
        resolve(res);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
          resolve(res);
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const shareSaveAccountApi = ({data, token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.SHARE_SAVE_ACCOUNT,
    token,
    method: "POST",
    isFormdata: true,
    data
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        callToast({type:"success",msg:"Shared Successfully"})
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getSchedulerAccountsApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.SCHEDULER_ACCOUNT,
    token
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  .catch(error => {
      console.error('Error:', error);
      reject(error)
  });
});

export const postSchedulerApi = ({data,token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.SCHEDULER_POST,
    method: "POST",
    token,
    data
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        callToast({type:"success",msg:"Shared Successfully"})
        resolve()
      }
      else if(res.status==463) {
          // if(res.msg) { callToast({type:"error",msg:res.msg}) }
          resolve({msg:res?.msg||"Queue is not configured",url:res?.url})
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
          resolve()
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const favoriteApi = ({entry_id, add, showToast=false, token}) => new Promise((resolve, reject) => {
    const apiData = {
      url: add ? ApiConstants.FAVORITE_ADD : ApiConstants.FAVORITE_REMOVE,
      method: add ? "POST" : "DELETE",
      token,
      data: {
        entry_id,
        // mode: add ? "favorite" : "favorited"
      }
    }

    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
          if(showToast)
          {
            callToast({type:"success",msg:`${add ? "Added to" : "Removed from"} favorites`})
          }
          resolve();
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    // .catch(error => {
    //     console.error('Error:', error);
    //     reject(error)
    // });
});

export const getTagsApi = ({token}) => new Promise((resolve, reject) => {
    const apiData = {
      url: ApiConstants.TAG_LIST,
      token
    }

    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
          resolve(res.data);
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    // .catch(error => {
    //     console.error('Error:', error);
    //     reject(error)
    // });
});

export const updatePasswordApi = ({token,password}) => new Promise((resolve, reject) => {
    const apiData = {
      url: ApiConstants.UPDATE_PASSWORD,
      method: "POST",
      token,
      data: { new_password:password }
    }

    callApi(apiData)
    .then(res => {
        if(isApiSuccess(res.status)) {
          callToast({type:"success",msg:"Saved Successfully"});
          resolve();
        }
        else {
            if(res.msg) { callToast({type:"error",msg:res.msg}) }
        }
    })
    // .catch(error => {
    //     console.error('Error:', error);
    //     reject(error)
    // });
});

// export const updateProfileApi = ({token, name, website,blog, youtube_channel, bio, image, new_password}) => new Promise((resolve, reject) => {
//     const apiData = {
//       url: ApiConstants.UPDATE_PROFILE,
//       method: "POST",
//       token,
//       data: { name, website,blog, youtube_channel, bio, image, new_password}
//     }

//     callApi(apiData)
//     .then(res => {
//         if(isApiSuccess(res.status)) {
//           callToast({type:"success",msg:"Saved Successfully"});
//           resolve();
//         }
//         else {
//             if(res.msg) { callToast({type:"error",msg:res.msg}) }
//         }
//     });
// });

export const getUserInfoApi = ({token, mode=undefined}) => new Promise((resolve, reject) => {
  
  const apiData = {
    url: ApiConstants.USER_INFO,
    token,
    data: { mode }
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const getDigestListApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.DD_PREF,
    token
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const updateDigestApi = ({token, preference_type, value}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.DD_UPDATE,
    method: "POST",
    token,
    data: { preference_type, value }
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        callToast({type:"success",msg:"Saved Successfully"});
        resolve();
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const getPaymentsInfo = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.SUBSCRIPTION_INFO,
    method: "GET",
    token,
    // data: { preference_type, value }
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        // callToast({type:"success",msg:"Saved Successfully"});
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const updateEmailApi = ({token, email}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.UPDATE_EMAIL,
    method: "POST",
    token,
    data: { email }
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        callToast({type:"success",msg:res.msg});
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const getBillingInfoApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.ACCOUNT_BILLING,
    token
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const getOpmlUrlApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.OPML_EXPORT,
    token
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const getOpmlImportApi = ({reader_name,opml_file,token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.OPML_IMPORT,
    token,
    method: "POST",
    isFormdata: true,
    files: ['opml_file'],
    data: {
      reader_name,
      opml_file
    }
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
          reject(new Error(res.msg));
      }
  });
});

export const getOpmlStatusApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.OPML_STATUS,
    token
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const getDDPrefApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.DD_PREF,
    token
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const updateDDPrefApi = ({token, key, value}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.DD_UPDATE,
    token,
    method: "POST",
    data: {key, value}
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const teamAddApi = ({token, name, email}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.TEAM_ADD,
    token,
    method: "POST",
    data: {name, email}
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const teamRemoveApi = ({token, member_id}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.TEAM_REMOVE,
    token,
    method: "DELETE",
    data: {member_id}
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});

export const teamListApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.TEAM_LIST,
    token,
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});


export const getNavbarSearch = ({q, limit=undefined, token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.SEARCH_NAVBAR,
      token,
      data: {
        q,
        limit
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getPodcastBrowseApi = ({category_name=undefined, limit=undefined, token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.PODCAST_BROWSE,
      token,
      data: {
        // category_name ,
        limit
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getPodcastCategoryListApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.PODCAST_CAT_LIST,
      token,
      data: {
        // q ,
        // limit
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getPodcastCategoryFoldersApi = ({category_name, token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.PODCAST_CAT_DETAIL,
      token,
      data: {
        category_name 
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getPodcastRecommendedApi = ({folder_id, site_id, token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.PODCAST_RECOMMENDED,
      token,
      data: {
        folder_id,
        site_id
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getOragnizeListApi = ({folder_id=undefined, token}) => new Promise((resolve, reject) => {

  const apiData = {
      url: ApiConstants.ORGANIZE_LIST,
      token,
      data: !!folder_id && folder_id>0 ? { folder_id } : undefined
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getIndustryPackagesListApi = ({token}) => new Promise((resolve, reject) => {

  const apiData = {
      url: ApiConstants.INDUSTRY_LIST,
      token
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getIndustryPackagesFeaturesApi = ({token}) => new Promise((resolve, reject) => {

  const apiData = {
      url: ApiConstants.INDUSTRY_FEATURES,
      token
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getDeactivateAccountApi = ({reason="",notes="", token}) => new Promise((resolve, reject) => {

  const apiData = {
      url: ApiConstants.ACCOUNT_DEACTIVATE,
      token,
      method: "DELETE",
      data: {
        reason,
        notes
      }
  }
  callApi(apiData)
  .then(res => {
    // console.log('getDeactivateAccountApi', res)
      if(isApiSuccess(res.status)) {
        callToast({type:"success",msg:"Deactivating Account... Kindly Wait..."})
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getHistoryAccountApi = ({url="", token}) => new Promise((resolve, reject) => {

  const apiData = {
      url: ApiConstants.ACCOUNT_HISTORY, 
      token,
      method: "POST",
      data: {
        url
      }
  }
  callApi(apiData)
  .then(res => {
    // console.log('getDeactivateAccountApi', res)
      if(isApiSuccess(res.status)) {
        // callToast({type:"success",msg:"Deactivating Account... Kindly Wait..."})
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
  // .catch(error => {
  //     console.error('Error:', error);
  // });
});

export const getInvoiceListApi = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.INVOICE_LIST,
      token,
      method: "GET",
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
});

export const downloadInvoiceApi = ({invoice_id, token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.INVOICE_DOWNLOAD,
      token,
      method: "GET",
      data: {
        invoice_id
      }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
});

export const getSubscriptionModalInfo = ({token}) => new Promise((resolve, reject) => {
  const apiData = {
      url: ApiConstants.SUBSCRIPTION_MODAL_INFO,
      token,
      method: "GET",
      // data: {
      //   invoice_id
      // }
  }
  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  })
});

export const getCheckoutSessionInfo = ({token, plan, trial_end=false}) => new Promise((resolve, reject) => {
  const apiData = {
    url: ApiConstants.SUBSCRIPTION_CHECKOUT,
    method: "POST",
    token,
    data: {
      plan,
      trial_end
    }
  }

  callApi(apiData)
  .then(res => {
      if(isApiSuccess(res.status)) {
        // callToast({type:"success",msg:"Saved Successfully"});
        resolve(res.data);
      }
      else {
          if(res.msg) { callToast({type:"error",msg:res.msg}) }
      }
  });
});